.hover-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .cardcheckbox,
  .MuiIconButton-root {
    display: none;
  }

  &:hover {
    .cardcheckbox,
    .MuiIconButton-root {
      display: block;
    }
  }
}
