@font-face {
  font-family: "DM Sans";
  src: url("./DM-Sans/DMSans-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("./DM-Sans/DMSans-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("./DM-Sans/DMSans-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
