.dots {
  position: absolute;
  // background: red;
  margin: auto;
  left: -10%;
  z-index: -1;
  display: grid;
  // grid-template-columns: repeat(120, 12px);
  grid-template-columns: repeat(150, 12px);
  grid-template-rows: repeat(25, 12px);
  place-items: center;
  transform: rotate(-25deg) skewX(10deg);
}

.dot {
  width: 6px;
  height: 6px;
  // background-color: hsl(var(--hue), 90%, 70%);
  background: linear-gradient(90deg, #060721 -40252.09%, #2160dc -28949.37%, #273f62 13337.24%);
  opacity: 0.6;
  border-radius: 50%;
  -webkit-animation: dot 40s calc(var(--delay1, 0s) + var(--delay2, 0s)) infinite ease-in-out;
  animation: dot 40s calc(var(--delay1, 0s) + var(--delay2, 0s)) infinite ease-in-out;
}

@-webkit-keyframes dot {
  0% {
    transform: rotate(0deg) translateY(10px) translateX(10px);
  }
  25% {
    transform: rotate(-360deg) translateY(50px) translateX(50px);
  } /* ignored */
  50% {
    transform: rotate(-740deg) translateY(100px) translateX(100px);
  } /* ignored */
  75% {
    transform: rotate(-360deg) translateY(50px) translateX(50px);
  } /* ignored */
  100% {
    transform: rotate(0deg) translateY(10px) translateX(10px);
  }
}

@keyframes dot {
  0% {
    transform: rotate(0deg) translateY(50px) translateX(50px);
  }
  25% {
    transform: rotate(-360deg) translateY(75px) translateX(75px);
  } /* ignored */
  50% {
    transform: rotate(-740deg) translateY(150px) translateX(150px);
  } /* ignored */
  75% {
    transform: rotate(-360deg) translateY(75px) translateX(75px);
  } /* ignored */
  100% {
    transform: rotate(0deg) translateY(50px) translateX(50px);
  }
}

$delay1: 0.1s;
$delay2: 0.1s;
$child2Up: -149; // start from 1
$child2Down: 0; // start from 150

@mixin dot-delay($delay1, $delay2, $child1, $child2Up, $child2Down) {
  .dot:nth-child(150n + #{$child1}) {
    --delay1: #{$delay1};
  }
  .dot:nth-child(n + #{$child2Up}):nth-child(-n + #{$child2Down}) {
    --delay2: #{$delay2};
  }
}

@for $child1 from 1 through 150 {
  $delay1: $delay1 - 0.1s;
  $delay2: $delay2 - 0.1s;
  $child2Up: $child2Up + 150;
  $child2Down: $child2Down + 150;
  @include dot-delay($delay1, $delay2, $child1, $child2Up, $child2Down);
}

// $upLimit: -150;
// $downLimit: 0;
// $difference: 0;

// @for $child1 from 1 through 4 {
//   $upLimit: $upLimit + 150;
//   $downLimit: $downLimit + 150;
//   $difference: $difference + 5;
//   .dot:nth-child(n + #{$upLimit + $difference}):nth-child(-n + #{$downLimit - $difference}) {
//     // 0 150
//     background: transparent;
//   }
// }
