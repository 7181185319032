input[type="checkbox"] {
  @apply appearance-none;
  @apply bg-transparent;
  @apply m-0;

  @apply w-5 h-5;
  @apply border border-black/[0.24] dark:border-white/20;
  @apply rounded-md;
  @apply grid place-content-center;
}

input[type="checkbox"]:checked {
  @apply bg-primary-500;
  @apply border-none;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  @apply bg-white;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
