.slick-dots {
  li {
    width: 8px !important;
    height: 8px !important;
    margin: 0px 2px !important;

    button {
      width: 8px !important;
      height: 8px !important;

      &::before {
        width: 8px !important;
        height: 8px !important;
        font-size: 8px !important;
        color: white !important;
        opacity: 6% !important;
      }
    }

    &.slick-active {
      button {
        &::before {
          color: white !important;
          opacity: 100% !important;
        }
      }
    }
  }
}
